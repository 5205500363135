import * as React from 'react';
import { LayoutProvider, Wrapper } from '../components/layout';
import Seo from '../components/seo';
import { cannonStyled } from '../theme';
import { ResponsiveContainer } from '../atoms/Container';
import { useCannonStyletron } from '../theme/CannonSafesTheme';
import { useTableOrMobile } from '../hooks';
import ErrorMessage from '../components/sections/ErrorMessage';
import { graphql, useStaticQuery } from 'gatsby';

//Query
const query = graphql`
  query {
    strapiErrorMessage404 {
      title
      errorMessage
      action
      href
      media {
        url
      }
    }
  }
`;

//Interface

export interface IErrorMessage {
  strapiErrorMessage404: {
    title: string;
    errorMessage: string;
    action: string;
    href: string;
    media: {
      url: string;
    };
  };
}

//Styled components
const MainWrapper = cannonStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: props.isMobile ? '50px 0px 50px 0px' : '50px 0px 100px 0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

const NotFoundPage = (props: IErrorMessage) => {
  //Hooks
  const data = useStaticQuery<IErrorMessage>(query);
  const [isMobile, isTablet] = useTableOrMobile();

  return (
    <LayoutProvider>
      <Seo title='404: Not found' />
      <MainWrapper isMobile={isMobile}>
        <ResponsiveContainer flexDirection='column' bottomLeft={false}>
          <ErrorMessage
            title={data.strapiErrorMessage404.title}
            errorMessage={data.strapiErrorMessage404.errorMessage}
            action={data.strapiErrorMessage404.action}
            href={data.strapiErrorMessage404.href}
            media={data.strapiErrorMessage404.media.url}
          />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default NotFoundPage;
