import React from 'react';
import { RoundedButton } from '../../../atoms/Button';
import { cannonStyled, NSH3, TWH4, useCannonStyletron } from '../../../theme';
import Image404 from '../../../assets/illustration-404.svg';
import { useAPIURL } from '../../../hooks';
import { IErrorMessage } from '../../../pages/404';

const ErrorMessage = (props: IErrorMessage) => {
  //Hooks
  const [css, theme] = useCannonStyletron();
  const API_URL = useAPIURL();

  return (
    <>
      <img src={API_URL + props.media} />
      <NSH3
        color={theme.cannonColors.primaryBlue}
        weight={900}
        align={'center'}
        className={css({ paddingBottom: '20px' })}
      >
        {props.title}
      </NSH3>
      <TWH4
        color={theme.cannonColors.secondarySilver}
        weight={400}
        align={'center'}
        className={css({ paddingBottom: '10%' })}
      >
        {props.errorMessage}
      </TWH4>
      <RoundedButton
        color={'primary'}
        size='compact'
        onClick={() => (window.location.href = `${props.href}`)}
      >
        {props.action}
      </RoundedButton>
    </>
  );
};

export default ErrorMessage;
